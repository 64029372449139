export default [
	{
		title: 'Keuangan',
		icon: 'FileTextIcon',
		permission: 'finance',
		children: [
			{
			    title: 'Checklist Tagihan',
				route: 'finance-checklist',
			    permission: 'finance.checklist',
			},
			{
				title: 'Penagihan Vendor',
				permission: 'finance.billing',
				// route: 'billing-rent'
				children: [
					{
						title: 'Sewa Kendaraan',
						route: 'billing-rent',
						permission: 'finance.billing.rent',
					},
					{
						title: 'Reimbursement',
						route: 'internal-own-risk',
						permission: 'finance.billing.risk',
					},
					// {
					// 	title: 'Own Risk',
					// 	route: 'internal-own-risk',
					// 	permission: 'finance.billing.risk',
					// }
				]
			},
			{
			    title: 'Biaya BBM',
				route: 'fuel-cost',
			    permission: 'finance.fuel',
			}
		]
	},
]