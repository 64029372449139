export const showDataNotification = (notification) => {

  // console.log(notification)
  let params = {params: { id: notification.data.data.id }}
  
  if (notification.type.indexOf('\Billing') > -1) {
    if(notification.userType == 'vendor')
      params.name = 'v-billing-rent-view'
    else
      params.name = 'billing-rent-view'
    return params
  } else if (notification.type.indexOf('VehicleMutationReceived') > -1) {
    params.name = 'mutation-in-view'
    params.permission = 'operational.mutation.in'
    return params
  } else if (notification.type.indexOf('VehicleMutationCreated') > -1) {
    params.name = 'mutation-view'
    params.permission = 'operational.mutation.mutation'
    return params
  }else if (notification.type.indexOf('VehicleMutationSent') > -1) {
    params.name = 'mutation-out-view'
    params.permission = 'operational.mutation.out'
    return params
  } else if (notification.type.indexOf('Claim') > -1) {
    params.name = 'vehicle-claim-view'
    return params
  } else if (notification.type.indexOf('Spb') > -1) {
    if(notification.userType == 'vendor')
      params.name = 'spb-vendor-detail'
    else
      params.name = 'spb-detail'
    return params
  } else if (notification.type.indexOf('Staff') > -1) {
    params.name = 'staff-view'
    return params
  } else if (notification.type.indexOf('Maintenance') > -1) {
    params.name = 'perawatan-detail'
    return params
  } else if (notification.type.indexOf('IssueCreated') > -1) {
    params.name = 'issue-kendaraan-detail'
    params.permission = 'maintenance.issue'
    return params
  }else if (notification.type.indexOf('VehicleGetback') > -1) {
    params.name = 'vehicle-getback-view'
    params.permission = 'vehicle.getback'
    return params
  }else if (notification.type.indexOf('BappBast') > -1) {
    params.params.id = notification.billing.id
    params.name = 'billing-rent-view'
    return params
  }
  return null;
}
export const _ = {}
