export default [
    {
        title: 'Perawatan',
        route: null,
        icon: 'SettingsIcon',
        permission: 'maintenance',
        children: [
            // {
            //     title: 'Semua Perawatan',
            //     route: 'semua-perawatan',
            //     permission: 'maintenance.all_maintenance',
            // },
            {
                title: 'Perawatan Non Rutin',
                route: 'issue-kendaraan',
                permission: 'maintenance.issue',
            },
            {
                title: 'Rencana Perawatan',
                route: 'rencana-perawatan',
                permission: 'maintenance.plan',
            },
            {
                title: 'Semua Perawatan',
                route: 'perawatan',
                permission: 'maintenance.maintenance',
            },
            {
                title: 'Notifikasi & Pengingat',
                route: 'reminder',
                permission: 'maintenance.reminder',
            },
        ]
    }
]
