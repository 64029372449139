export default [
  {
    title: 'Operasional',
    route: null,
    icon: 'GlobeIcon',
    permission: 'operational',
    children: [
      {
        title: 'Checklist',
        route: 'checklist',
        permission: 'operational.checklist',
      },
      {
        title: 'BBM',
        route: 'refueling',
        permission: 'operational.fuel',
      },
      {
        title: 'Pajak & KIR',
        route: 'tax',
        permission: 'operational.tax',
      },
      {
        title: 'Mutasi Kendaraan',
        // route: 'second-page',
        permission: 'operational.mutation',
        children: [
          {
            title: 'Permintaan Mutasi',
            route: 'mutation',
            permission: 'operational.mutation.mutation',
          },
          {
            title: 'Mutasi Keluar',
            route: 'mutation-out',
            permission: 'operational.mutation.out',
          },
          {
            title: 'Mutasi Masuk',
            route: 'mutation-in',
            permission: 'operational.mutation.in',
          }
        ]
      },
      {
        title: 'Penggantian',
        route: 'vehicle-change',
        permission: 'operational.change',
      },
      // {
      //   title: 'Laporan',
      //   // route: 'second-page',
      //   permission: 'operational.report',
      // },
      {
        title: 'Monitor Maintenance MT',
        route: 'monitor-maintenance',
        permission: 'operational.monitor',
      },
    ]
  }
]
