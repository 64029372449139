export default [
    {
        title: 'Penagihan',
        icon: 'FileTextIcon',
        permission: 'billing',
        children: [
            {
                title: 'Sewa Kendaraan',
                route: 'v-billing-rent',
                permission: 'billing.rent',
            },
            {
                title: 'Own Risk',
                route: 'own-risk',
                permission: 'billing.risk',
            }
        ]
    },
]