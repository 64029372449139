export default [
  {
    title: 'Kontak',
    route: null,
    icon: 'UsersIcon',
    permission: 'master.contact',
    children: [
      // {
      //   title: 'Semua Kontak',
      //   route: 'contactall',
      // },
      {
        title: 'Pegawai',
        route: 'staff',
        permission: 'master.contact.staff',
      },
      {
        title: 'Vendor',
        route: 'vendor',
        permission: 'master.contact.vendor',
      },
      {
        title: 'Bengkel',
        route: 'workshop',
        permission: 'master.contact.workshop',
      },
      {
        title: 'Supplier',
        route: 'supplier',
        permission: 'master.contact.supplier',
      },
      {
        title: 'Asuransi',
        route: 'assurance',
        permission: 'master.contact.assurance',
      },
    ]
  }


]
