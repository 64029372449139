<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="SettingsIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notification Perawatan</h4>
        <b-badge pill variant="light-primary"> {{ notifications.length }} New </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      ref="dropdownItem"
    >
      <b-dropdown-item
        v-for="notification in notifications"
        :key="notification.id"
        @click="showNotification(notification)"
      >
          <b-media>
            <template #aside>
              <b-avatar size="32" :src="require('@/assets/images/avatars/user.svg')" />
            </template>
            <!-- <b-badge pill :variant="notification.diff>1?'success':notification.diff>0?'warning':'danger'" style="float:right;">
              {{notification.diff>0?'< '+notification.diff+' hari':notification.diff==0?'< 3 Bulan':'< 1 Bulan'}}
            </b-badge> -->
            <p class="media-heading">
              <b>Perawatan {{ notification.name }}</b>
            </p>
            <small class="notification-text">
              {{ notification.diff < 0?'Perawatan Lewat ':notification.diff==0?'Sudah Waktunya Perawatan ':'Perawatan Kurang ' }}
              {{ notification.diff < 0?notification.diff.replace("-","")+' hari':notification.diff==0?'':notification.diff+' hari' }}
            </small>
          </b-media>
      </b-dropdown-item>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAll"
        >Read all notifications</b-button
      > -->
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BDropdownItem,
  BAvatar,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import '@axios'
import { showDataNotification } from '@core/comp-functions/ui/notification'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BDropdownItem,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      user: null,
      userType: null,
    }
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      // console.log('1')
      this.isConnected = true
    },

    newimg() {
      // Fired when the socket connects.
      console.log('newimg')
    },
    message() {
      // Fired when the socket connects.
      console.log('message')
    },
  },
  watch: {
    '$store.state.notification.dataMaintenance': function () {
      this.notifications = this.$store.state.notification.dataMaintenance
      //       console.log("test", this.notifications)
    },
  },
  mounted() {
    // console.log('craeted', this.notifications)
    this.loadNotification()
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    setInterval(() => {
			this.loadNotification()
		}, 300000)
  },
  methods: {
    // onDropdownHide(bvEvent) {
    //   bvEvent.preventDefault();
    // },
    loadNotification() {
      let dataNotif = [];
      this.$http.get('/notification/maintenance').then(res => {
        // if (this.userType == 'vendor'){
        //   for (let notif of res.data.data){
        //     if (JSON.stringify(notif).includes(this.user.id)){
        //       dataNotif.push(notif);
        //     }
        //   }
        // }
        // else {
        //   dataNotif = res.data.data
        // }
        dataNotif = res.data.data
        this.$store.commit('notification/UPDATEMAINTENANCE', dataNotif)

        // this.notifications = res.data.data
        // this.count = res.data.recordsTotal
        // console.log(this.notifications)
      })
    },
    // readAll() {
    //   this.$http.put('/notification/read').then(res => {
    //     this.$store.commit('notification/READALL')
    //   })
    // },
    // readItem(item){
    //   this.$http.put('/notification/' + item.id + '/read').then(res => {
    //     this.$store.commit('notification/READ', item.id)
    //   })
    // },
    showNotification(notification) {
      // console.log(notification)
      // console.log(this.$store.state.profile.info)
      var userType = this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
      notification.userType = userType
      notification.type = 'Maintenance'
      notification.data = {data:{id:notification.id}}
      var route = showDataNotification(notification)
      if (route != null){
        if(this.$route.name != route.name || this.$route.params.id != route.params.id){
          if(route.permission){
            var permission = this.$store.state.profile.info.permissions.find(x => x == route.permission)
            if(!permission){
              return
            }
          }
          route.params.reload = true
          this.$router.push(route)
        }
      }
    },
  },
  setup() {
    /* eslint-disable global-require */
    // const notifications = this.data
    /* eslint-disable global-require */

    // this.$http.get('api/notification').then(res=>{
    //   console.log(res)
    // })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
  .dropdown-item {
    padding: 0;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
  }
</style>
