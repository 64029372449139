export default [
  {
    title: 'Laporan',
    route: null,
    icon: 'FileTextIcon',
    permission: 'report',
    children: [
      {
        title: 'Inventory',
        // route: 'second-page',
      },
      {
        title: 'TMS',
        // route: 'second-page',
      },
      {
        title: 'Perawatan',
        // route: 'second-page',
      },
      {
        title: 'Laporan Permintaan Pembelian',
        // route: 'second-page',
      },
    ]
  }


]
