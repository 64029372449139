export default [
  {
    title: 'Setting Master',
    route: null,
    icon: 'FileTextIcon',
    permission: 'master',
    children: [
      {
        title: 'Application',
        icon: 'SettingsIcon',
        route: 'application',
        permission: 'master.application',
      },
      {
        title: 'Setting Umum',
        icon: 'CloudLightningIcon',
        route: null,
        permission: 'master.general',
        children: [
          {
            title: 'Wilayah',
            route: 'region',
            icon: 'CircleIcon',
            permission: 'master.general.branch',
          },
          {
            title: 'Administrasi',
            route: 'jabatan',
            icon: 'CircleIcon',
            permission: 'master.general.administration',
          },
        ],
      },
      {
        title: 'Skko',
        icon: 'AwardIcon',
        route: 'skko',
        permission: 'master.skko',
      },
      {
        title: 'Kontrak',
        icon: 'FileIcon',
        route: 'contract',
        permission: 'master.contract',
      },
      {
        title: 'Project',
        icon: 'FlagIcon',
        route: 'project',
        permission: 'master.project',
      },
      {
        title: 'User',
        icon: 'UsersIcon',
        route: 'user',
        permission: 'master.user',
      },
      {
        title: 'Role',
        icon: 'SettingsIcon',
        route: 'role',
        permission: 'master.role',
      },
      {
        title: 'Kendaraan',
        route: '',
        icon: 'TruckIcon',
        permission: 'master.vehicle',
        children: [
          {
            title: 'Tipe',
            route: 'vehicle-type',
            permission: 'master.vehicle.type',
          },
          {
            title: 'Pabrikan',
            route: 'vehicle-manufacture',
            permission: 'master.vehicle.manufacture',
          },
          {
            title: 'Poros Ban Kendaraan',
            route: 'operasional-tire-axle',
            permission: 'master.vehicle.axle',
          },
          {
            title: 'Body Kendaraan',
            route: 'vehicle-body',
            permission: 'master.vehicle.body',
          },
          {
            title: 'Kelengkapan Kendaraan',
            route: 'vehicle-property',
            permission: 'master.vehicle.property',
          },
          {
            title: 'Varian Kendaraan',
            route: 'vehicle-variant',
            permission: 'master.vehicle.variant',
          }
        ]
      },
      {
        title: 'Operasional',
        route: '',
        icon: 'TruckIcon',
        permission: 'master.operational',
        children: [
          {
            title: 'BBM Kendaraan',
            route: 'operasional-fuel',
            permission: 'master.operational.fuel',
          },
          {
            title: 'Ukuran Ban Kendaraan',
            route: 'operasional-tire-size',
            permission: 'master.operational.tire_size',
          },
          {
            title: 'Interval KM Perawatan',
            route: 'operasional-maintenance-interval',
            permission: 'master.operational.interval',
          },
          {
            title: 'Jenis Perawatan',
            route: 'operasional-maintenance-item',
            permission: 'master.operational.maintenance_type',
          },
          {
            title: 'Jenis Pengingat',
            route: 'operasional-reminder',
            permission: 'master.operational.reminder',
          },
          {
            title: 'Jenis Isu/Kerusakan',
            route: 'operasional-issue-type',
            permission: 'master.operational.issue_type',
          }
        ]
      },
      // {
      //   title: 'Kendaraan',
      //   // route: 'setting-page',
      // },
      // {
      //   title: 'Operational',
      //   // route: 'setting-page',
      // },
      // {
      //   title: 'Variant Kendaraan',
      //   // route: 'setting-page',
      // },
    ]
  }


]
