import contact from "./contact"
import inventory from "./inventory"
import laporan from "./laporan"
import operational from "./operational"
import maintanance from "./maintanance"
import orderUnit from "./order-unit"
import setting from "./setting"
import tms from "./tms"
import vehicle from "./vehicle"
import finance from "./finance"

export default [
  ...setting,
  ...contact,
  ...orderUnit,
  ...vehicle,
  ...operational,
    ...maintanance,
    // ...inventory,
  // ...tms,
  ...laporan,
  ...finance
]