<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo" v-if="appLogoImage">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text text-wrap" style="width:120px;">
                {{ appName && appName.length > 0 ? appName: "FMS" }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <br />
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import originMenu from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import _ from 'lodash'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    var permissions = this.$store.state.profile.info.permissions
    // var navMenuItems = [...originMenu]
    var navMenuItems = _.cloneDeep(originMenu)
    for (var i = 0; i < navMenuItems.length; i++) {
      if (navMenuItems[i].title == 'Notifikasi') {
        navMenuItems[i].tag = this.$store.state.notification.data.length
      }
      this.checkPermission(navMenuItems, permissions)

      // console.log(current)
    }

    return {
      navMenuItems,
      // App Name
      data:null,
      appName: this.$store.state.appConfig.app.appName,
      appLogoImage: this.$store.state.appConfig.app.appLogoImage,
    }
  },
  watch: {
    '$store.state.appConfig.app.appName': function () {
      this.appName = this.$store.state.appConfig.app.appName
    },
    '$store.state.appConfig.app.appLogoImage': function () {
      this.appLogoImage = this.$store.state.appConfig.app.appLogoImage
    },
    '$store.state.notification.data': function () {
      var navMenuItems = this.navMenuItems

      for (var i = 0; i < navMenuItems.length; i++) {
        if (navMenuItems[i].title == 'Notifikasi') {
          navMenuItems[i].tag = this.$store.state.notification.data.length
        }
      }

      this.navMenuItems = navMenuItems
    },
    '$store.state.profile.info': function () {
      var navMenuItems = _.cloneDeep(originMenu)
      var permissions = this.$store.state.profile.info.permissions
      for (var i = 0; i < navMenuItems.length; i++) {
        this.checkPermission(navMenuItems, permissions)
      }

      this.navMenuItems = navMenuItems
    },
  },
  created(){
    this.fetchData()
  },
  methods: {
    fetchData(){
        this.$http.get('application').then(res=>{
          this.data = res.data.data
          this.$store.commit('appConfig/UPDATE_APP', {
            appName: this.getConfigData('appName'),
            appLogoImage: this.getConfigData('appLogoImage'),
          })
        })
    },
    getConfigData(config){
      for (let index = 0; index < this.data.length; index++) {
        const configData = this.data[index];
        if (configData.config === config){
          return configData.data_url ? configData.data_url : configData.data
        }
      }
    },
    checkPermission(list, permissions) {
      for (var i = 0; i < list.length; i++) {
        var current = list[i]
        if (current.permission && permissions.indexOf(current.permission) <= -1) {
          list.splice(i, 1)
        }
        if (current.children) this.checkPermission(current.children, permissions)
      }
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
    )

    //    const navMenuItems = computed(() => navMenuItems )

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
