export default [
    {
        title: 'Setting',
        route: null,
        icon: 'SettingsIcon',
        permission: 'setting',
        children: [
            {
                title: 'Data Perusahaan',
                route: 'data-perusahaan',
                permission: 'setting.company',
            },
            {
                title: 'Asuransi',
                route: 'vendor-assurance',
                permission: 'setting.assurance',
            },
            {
                title: 'Bengkel',
                route: 'vendor-workshop',
                permission: 'setting.workshop',
            }
        ]
    }
]