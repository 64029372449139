export default [
  {
    title: 'Kendaraan',
    route: null,
    icon: 'TruckIcon',
    permission: 'vehicle',
    children: [
      {
        title: 'Semua Kendaraan',
        route: 'vehicles',
        permission: 'vehicle.vehicle',
      },
      {
        title: 'Sebaran Kendaraan',
        route: 'vehicle-chart',
        permission: 'vehicle.chart',
      },
      {
        title: 'Perawatan',
        route: 'perawatan',
        permission: 'vehicle.maintenance',
      },
      {
        title: 'Pengambilan Unit',
        route: 'vehicle-getback',
        permission: 'vehicle.getback',
      },
      {
        title: 'Penggantian Unit',
        route: 'v-vehicle-change',
        permission: 'vehicle.change',
      },
      {
        title: 'Klaim Kendaraan',
        route: 'vehicle-claim',
        permission: 'vehicle.claim',
      },
      {
        title: 'Laporan',
        route: 'vehicle-report',
        permission: 'vehicle.report',
      },
      {
        title: 'Pajak & Kir',
        route: 'v-tax',
        permission: 'vehicle.tax',
      },
      // {
      //   title: 'Mutasi Kendaraan',
      //   // route: 'second-page',
      // },
    ]
  }


]
