
import internal from "../menu/internal/index"
import vendor from "../menu/vendor/index"
import billing from "../menu/vendor/billing"

export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    permission: 'master',
    children: [
      {
        title: 'Operasional',
        route: 'home-operasional',
      },
      {
        title: 'Keuangan',
        route: 'home-finance',
      },
      {
        title: 'Perawatan',
        route: 'home-maintenance',
      }
    ]
  },
  {
    title: 'Home',
    route: 'home-vendor',
    icon: 'HomeIcon',
    permission: 'billing',
  },
  {
    title: 'Notifikasi',
    route: 'notification',
    icon: 'AlertCircleIcon',
    tagVariant: 'light-danger'
  },
  ...vendor,
  ...internal,
  ...billing
  // {
  //   title: 'Penagihan',
  //   icon: 'LifeBuoyIcon',
  //   permission: 'billing',
  //   children: [
  //     {
  //         title: 'Sewa Kendaraan',
  //         permission: 'billing',
  //     },
  //     {
  //         title: 'Own Risk',
  //         permission: 'billing',
  //     }
  //   ]
  // },
]
