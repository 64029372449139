export default [
  {
    title: 'Pemesanan Unit',
    route: null,
    icon: 'ArchiveIcon',
    permission: 'spb',
    children: [
      {
        title: 'Semua Daftar SPB',
        route: 'spb',
        permission: 'spb.spb'
      },
      {
        title: 'Daftar SPB',
        route: 'spb-vendor',
        permission: 'spb.spb_vendor'
      },
      {
        title: 'Persiapan Unit',
        route: 'persiapan-unit',
        permission: 'spb.prepare'
      },
      {
        title: 'Persiapan Unit',
        route: 'persiapan-unit-vendor',
        permission: 'spb.prepare_vendor'
      },
      {
        title: 'Pemeriksaan',
        route: 'pemeriksaan',
        permission: 'spb.check'
      },
      {
        title: 'Penerimaan',
        route: 'penerimaan',
        permission: 'spb.receive'
      },
      {
        title: 'Serah Terima',
        route: 'serah-terima',
        permission: 'spb.handover'
      },
      {
        title: 'Serah Terima',
        route: 'serah-terima-vendor',
        permission: 'spb.handover_vendor'
      },
      {
        title: 'Distribusi',
        route: 'distribute',
        permission: 'spb.distribute'
      },
    ]
  }


]
